.newChat {
    width: 35%;
    max-width: 415px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #DDD;
    transition: all ease 0.5s;
}
.newChat--head {
    display: flex;
    background-color: #00BFA5;
    align-items: center;
    padding: 60px 15px 15px 15px;
}
.newChat--backbutton {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.newChat--headtitle {
    font-size: 19px;
    height: 40px;
    line-height: 40px;
    flex: 1;
    font-weight: bold;
    color: #FFF;
    margin-left: 20px;
}
.newChat--list {
    flex: 1;
    overflow-y: auto;
}
.newChat--list::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.newChat--list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}
.newChat--item {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
}
.newChat--item:hover {
    background-color: #f5f5f5;;
}
.newChat--itemavatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}
.newChat--itemname {
    font-size: 17px;
    color: #000;
}