.login {
    height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cardLogin {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    width: 320px;
    height: auto;
    border-radius: 10px;
}
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F8FE;
    color: #444;
}
.header img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.loginContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 20px 50px 20px;
    color: #444;
}
input {
    height: 45px;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

button {
    width: 100%;
    height: 45px;
    background-color: #2196F3;
    color: #FFF;
    font-weight: bold;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}

button:hover {
    background-color: #0D47A1;
}