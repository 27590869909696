.chatWindow {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.chatWindow--header {
    height: 60px;
    border-bottom: 1px solid #CCC;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chatWindow--headerinfo {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.chatWindow--avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 15px;
}
.chatWindow--name {
    font-size: 17px;
    color: #000;
}
.chatWindow--phone a {
    color: #000;
    text-decoration: none;
}
.chatWindow--phone a:hover {
    text-decoration: underline;
}
.chatWindow--headerbuttons {
    display: flex;
    align-items: center;
    margin-right: 15px;
}
.chatWindow--btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    transition: all ease 0.3s;
}

.chatWindow--body {
    flex:1;
    overflow-y: auto;
    background-color: #E5DDD5;
    background-size: cover;
    background-position: top;
    background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
    padding: 20px 30px;
}
.chatWindow--body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .chatWindow--body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }

.chatWindow--footer {
    height: 62px;
    display: flex;
    align-items: center;
}
.chatWindow--pre {
    display: flex;
    margin: 0 15px;
}
.chatWindow--inputarea {
    flex: 1;
}
.chatWindow--input {
    width: 100%;
    height: 40px;
    border: 0;
    outline: 0;
    background-color: #FFF;
    border-radius: 20px;
    font-size: 15px;
    color: #4a4a4a;
    padding-left: 15px;
}
.chatWindow--pos {
    display: flex;
    margin: 0 15px;
}

.chatWindow--emojiarea {
    height: 200px;
    overflow-y: hidden;
    transition: all ease 0.3s;
}

aside.emoji-picker-react {
    width: auto !important;
    background: none;
}
aside.emoji-picker-react .content-group:before {
    background: none;

}

.options--body {
    background-color: #EDEDED;
    width: 150px;
    position: absolute;
    top: 55px;
    right: 20px;
    display: block;
}

.options--option {
    cursor: pointer;
    padding: 10px;
}
.options--option:hover {
    background-color: #F5F5F5;
}