.chatIntro {
    background-color: #F8F9FA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 180px;
    border-bottom: 6px solid #4ADF83;
}
.chatIntro img {
    width: 450px;
    height: auto;
}
.chatIntro h1 {
    font-size: 32px;
    color: #525252;
    font-weight: normal;
    margin-top: 30px;
}
.chatIntro h2 {
    font-size: 14px;
    color: #777;
    font-weight: normal;
    margin-top: 20px;
    line-height: 20px;
    text-align: center;
}